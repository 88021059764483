var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mk-card-radius pa-4" },
    [
      _c("PageTitle", {
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "Project Details" : "প্রজেক্টের বিবরণ",
        },
      }),
      _c(
        "m-container",
        { staticClass: "px-6 py-4" },
        [
          _c(
            "v-expansion-panels",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            _vm._l(_vm.project.project_steps, function (step, i) {
              return _c(
                "v-expansion-panel",
                { key: i },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "expansion-header" },
                    [
                      !_vm.$language
                        ? _c("v-col", { staticClass: "pa-0" }, [
                            _vm._v("Step " + _vm._s(i + 1) + " Details"),
                          ])
                        : _c("v-col", { staticClass: "pa-0" }, [
                            _vm._v(_vm._s(i + 1) + " (তম) ষ্টেপের বিস্তারিত"),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("step-details-form-show", {
                        attrs: {
                          "step-details": step,
                          readonly: true,
                          "step-ok": _vm.stepOkArray[i],
                        },
                        on: {
                          "update:stepOk": function ($event) {
                            return _vm.$set(_vm.stepOkArray, i, $event)
                          },
                          "update:step-ok": function ($event) {
                            return _vm.$set(_vm.stepOkArray, i, $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }