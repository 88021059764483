<template>
  <v-container class="px-7 py-4">
    <div>
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field
            v-model="stepDetails.title"
            outlined
            :label="!$language ? 'Title' : 'টাইটেল'"
            :placeholder="
              !$language ? 'Enter the name of the step...' : 'স্টেপের নাম লিখুন'
            "
            :readonly="readonly"
          />
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="6" class="mr-10">
        <v-textarea
          v-model="stepDetails.description"
          outlined
          :label="!$language ? 'Description' : 'বর্ননা'"
          :placeholder="
            !$language ? 'Enter the name of the step...' : 'স্টেপের নাম লিখুন'
          "
          :readonly="readonly"
        />
      </v-col>
    </v-row>

    <!-- <div v-if="stepDetails.image_url" class="d-flex flex-row flex-wrap">
      <div v-for="url in stepDetails.image_url" :key="url" class="pa-2">
        <v-img class="card-img" :src="url" contain width="240"></v-img>
      </div>
    </div> -->

    <div v-if="stepDetails.image_url" class="d-flex flex-row flex-wrap">
      <div
        v-for="(url, index) in stepDetails.image_url"
        :key="url"
        class="pa-2"
      >
        <m-rounded-button @click="download(url)"
          >{{ !$language ? "Download" : "ডাউনলোড" }}
          {{ index + 1 }}</m-rounded-button
        >
      </div>
    </div>

    <v-row class="mt-3">
      <v-col cols="5">
        <v-text-field
          v-model="stepDetails.weight"
          outlined
          :label="!$language ? 'Weight' : 'মান'"
          :placeholder="
            !$language ? 'The weight of the step...' : 'স্টেপের মান লিখুন'
          "
          :readonly="readonly"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="isTopicFormVisible" width="500px">
      <topic-request-form
        @saved="isTopicFormVisible = false"
      ></topic-request-form>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "StepDetailsForm",
  props: {
    stepDetails: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      isTopicFormVisible: false,
      difficultyLevels: ["Easy", "Medium", "Hard"],
    };
  },
  computed: {
    imageFileName() {
      return this.formData.image
        ? this.formData.image.file.name
        : "No Image Selected";
    },
  },
  methods: {
    download(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped></style>
