var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-7 py-4" },
    [
      _c(
        "div",
        [
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: !_vm.$language ? "Title" : "টাইটেল",
                      placeholder: !_vm.$language
                        ? "Enter the name of the step..."
                        : "স্টেপের নাম লিখুন",
                      readonly: _vm.readonly,
                    },
                    model: {
                      value: _vm.stepDetails.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.stepDetails, "title", $$v)
                      },
                      expression: "stepDetails.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mr-10", attrs: { cols: "6" } },
            [
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  label: !_vm.$language ? "Description" : "বর্ননা",
                  placeholder: !_vm.$language
                    ? "Enter the name of the step..."
                    : "স্টেপের নাম লিখুন",
                  readonly: _vm.readonly,
                },
                model: {
                  value: _vm.stepDetails.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.stepDetails, "description", $$v)
                  },
                  expression: "stepDetails.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.stepDetails.image_url
        ? _c(
            "div",
            { staticClass: "d-flex flex-row flex-wrap" },
            _vm._l(_vm.stepDetails.image_url, function (url, index) {
              return _c(
                "div",
                { key: url, staticClass: "pa-2" },
                [
                  _c(
                    "m-rounded-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.download(url)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(!_vm.$language ? "Download" : "ডাউনলোড") +
                          " " +
                          _vm._s(index + 1)
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: !_vm.$language ? "Weight" : "মান",
                  placeholder: !_vm.$language
                    ? "The weight of the step..."
                    : "স্টেপের মান লিখুন",
                  readonly: _vm.readonly,
                },
                model: {
                  value: _vm.stepDetails.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.stepDetails, "weight", $$v)
                  },
                  expression: "stepDetails.weight",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.isTopicFormVisible,
            callback: function ($$v) {
              _vm.isTopicFormVisible = $$v
            },
            expression: "isTopicFormVisible",
          },
        },
        [
          _c("topic-request-form", {
            on: {
              saved: function ($event) {
                _vm.isTopicFormVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }