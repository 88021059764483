<template>
  <v-card class="mk-card-radius pa-4">
    <!--    <v-card-title class="title mk-highlight-text mb-5"-->
    <!--      >{{!$language ? "Project Details" : "প্রজেক্টের বিবরণ"}}</v-card-title-->
    <!--    >-->
    <PageTitle
      previous-location
      :page-title="!$language ? 'Project Details' : 'প্রজেক্টের বিবরণ'"
    ></PageTitle>
    <m-container class="px-6 py-4">
      <v-expansion-panels v-model="currentStep">
        <v-expansion-panel v-for="(step, i) in project.project_steps" :key="i">
          <!--        need to add a Step counter.-->
          <v-expansion-panel-header class="expansion-header">
            <v-col v-if="!$language" class="pa-0"
              >Step {{ i + 1 }} Details</v-col
            >
            <v-col v-else class="pa-0"
              >{{ i + 1 }} (তম) ষ্টেপের বিস্তারিত</v-col
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <step-details-form-show
              :step-details="step"
              :readonly="true"
              :step-ok.sync="stepOkArray[i]"
            ></step-details-form-show>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </m-container>
  </v-card>
</template>

<script>
import StepDetailsFormShow from "#ecf/project/components/StepDetailsFormShow";
import projectworkService from "#ecf/project/services/ProjectWorkService";
import _ from "lodash";

export default {
  name: "ProjectDetailsPage",
  components: { StepDetailsFormShow },
  props: {
    courseId: String,
    project_id: String
  },
  provide() {
    return {
      $courseId: () => this.courseId
    };
  },
  data() {
    return {
      renderNow: false,
      project: undefined,
      creating: false,
      isTopicFormVisible: false,
      currentStep: 0,
      stepOkArray: [],
      answerOkArray: []
    };
  },
  computed: {
    batches() {
      const bs = this.batchesData.map(b => {
        if (!this.submissions[b.batch_id]) {
          return false;
        } else {
          const clone = { ...b };
          clone.submissions = this.submissions[b.batch_id];
          return clone;
        }
      });
      return _.compact(bs);
    },
  },
  async created() {
    await this.fetchProject();
  },
  methods: {
    async download() {
      try {
        window.open(this.project.url);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },

    async fetchProject() {
      try {
        this.renderNow = false;
        this.project = await projectworkService.get(
          this.project_id,
          this.courseId
        );
        this.batchesData = await projectworkService.getSharedBatches(
          this.project.object_id,
          this.courseId
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderNow = true;
      }

    },
    async fetchSubmissions() {
      try {
        const submissions = await projectworkService.getSubmissions(
          this.project_id,
          this.courseId,
          this.batch_id
        );
        this.submissions = submissions
          .filter(s => s.batch_id)
          .reduce((s_map, s) => {
            if (s_map[s.batch_id]) {
              s_map[s.batch_id].push(s);
            } else {
              s_map[s.batch_id] = [s];
            }
            return s_map;
          }, {});
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async submit_grade(payload) {
      try {
        this.$set(this.grading, payload.submission.student_id, true);
        await projectworkService.grade(
          this.project_id,
          payload.submission.course_id,
          payload.submission.batch_id,
          payload.submission.student_id,
          payload.score
        );
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.grading, payload.submission.student_id, false);
      }
    }
  }
};
</script>
